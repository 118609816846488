import React from 'react';
import Img from "gatsby-image";
import Link from "gatsby-link";
import {OfferSection} from "../../styles/OfferStyles";

const translations = {
  en: {
    Development: 'Development',
    Copywriting: 'Copywriting',
    Discover: 'Discover',
  },
  fr: {
    Development: 'Développement',
    Copywriting: 'Rédaction',
    Discover: 'Découvrir',
  },
};
function getTranslation(key, locale) {
  return translations[locale] && translations[locale][key] ? translations[locale][key] : translations['en'][key];
};

export default function OfferBlocks({ data, pageContext }) {
  return (
    <OfferSection>
      <div className="section-block dev">
        <div className="section-text">
          <h3>{getTranslation('Development',pageContext)}</h3>
          <p>{data.dev_title[pageContext]}</p>
          <Link className={'button secondary'}
                to={pageContext === 'en' ? '/development' : `/${pageContext}/development`}>
            {getTranslation('Discover',pageContext)}
          </Link>
        </div>
        {data.dev_image.asset.fixed && (
          <Img fixed={data.dev_image.asset.fixed} />
        )}
      </div>
      <div className="section-block copy">
        {data.dev_image.asset.fixed && (
          <Img fixed={data.copy_image.asset.fixed} />
        )}
        <div className="section-text">
          <h3>{getTranslation('Copywriting',pageContext)}</h3>
          <p>{data.copy_title[pageContext]}</p>
          <Link className={'button secondary'}
                to={pageContext === 'en' ? '/copywriting' : `/${pageContext}/copywriting`}>
            {getTranslation('Discover',pageContext)}
          </Link>
        </div>
      </div>
    </OfferSection>
  );
}
