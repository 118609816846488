import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { LogosSection } from "../../styles/LogosStyles";
import Img from "gatsby-image";

export default function Logos({ data, pageContext }) {
  const slickSettings = {
    accessibility: true,
    className: "slider variable-width",
    dots: false,
    draggable: false,
    infinite: false,
    initialSlide: 0,
    slidesToShow: 10,
    speed: 500,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          draggable: true,
        }
      }
    ]
  };
  return (
    <LogosSection>
      {data && data.map((logosContent, index) => (
          <div key={`logo_content_${index}`}>
            {logosContent.logoAndLink.length > 0 && (
              <div>
                <h2>{logosContent.title[pageContext]}</h2>
                <Slider {...slickSettings}>
                  {logosContent.logoAndLink &&
                    logosContent.logoAndLink.map((logo, index) => (
                      <div key={"logos_" + index}>
                        <a href={logo.link} target="_blank" rel="noreferrer" aria-label={`Link to the ${logo.logoTitle || ''} page.`}>
                          {logo.image && logo.image.asset.fluid && (
                            <Img fluid={logo.image.asset.fluid}
                                 style={{
                                   width: logo.image.asset.metadata.dimensions.width,
                                   height: logo.image.asset.metadata.dimensions.height
                                 }} />
                          )}
                          {!logo.image && logo.link && (
                            <a href={logo.link} target="_blank" rel="noreferrer"
                               aria-label={`Link to the ${logo.logoTitle || ''} page.`}>
                              <span className="bold-title">{logo.logoTitle}</span>
                            </a>
                          )}
                        </a>
                      </div>
                    ))}
                </Slider>
              </div>
            )}
          </div>
      ))}
    </LogosSection>
  )
}